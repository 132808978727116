import { graphql } from "gatsby"
import React from "react"
import "../assets/styles/index.scss"

import Scroll from "../components/global/scroll"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"

import Footer from "../components/footer/"
import Head from "../components/global/head"
import styles from "./form.module.scss"

const Application = () => {
  return (
    <MainLayout>
      <Head
        description="Great! Thanks for getting in touch! A member of the team will be in contact soon!"
        title="Thanks!"
        url="https://hardhat.com.au/thanks"
        image="https://hardhat.com.au/images/og.jpg"
      />
      <TopNavigation theme="dark" isHome={false} hideChat={true} />
      <div
        className={`${styles.content} ${
          styles.contentAlt
        } container text-center`}
      >
        <h1 className="mega1" style={{ marginBottom: "5vh" }}>
          Thanks!
        </h1>
        <p className="body1">
          Great! Thanks for getting in touch! A member of the team will be in
          contact soon!
        </p>
      </div>
      <Footer />
    </MainLayout>
  )
}
export default Application
